import styled from 'styled-components'
import { lighten } from 'polished'
import css from '@styled-system/css'
import get from 'lodash.get'
import { Text } from '../'

const Link = styled(Text).withConfig({
  shouldForwardProp: prop => !['lineHeight', 'underline'].includes(prop),
})`
  font-family: inherit;
  transition: color 150ms ease-out;
  ${props =>
    css({
      'text-decoration-line': props.underline ? 'underline' : 'none',
      color: props.color,
      'text-decoration-color': lighten(
        0.25,
        get(props.theme.colors, props.color)
      ),
      '&:hover': {
        color: lighten(0.1, get(props.theme.colors, props.color)),
      },
    })}
`

Link.defaultProps = {
  //   fontFamily: 'sans',
  //   as: 'h2',
  color: 'tomato',
  underline: true,
  //   mt: 0,
  as: 'a',
  fontFamily: 'sans',
}
Link.displayName = 'Link'
export default Link
