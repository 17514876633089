import React from 'react'
import Link from '../UI/Link'
import { Text } from '../UI'
import styled from 'styled-components'
import ScrollableLink from '../ScrollableLink'

const BreadCrumbs = ({ links }) => (
  <Text color="primary.0" fontSize={{ _: 'xs', md: 0 }}>
    {links.map(({ to, title }, i) => (
      <span key={i}>
        {to ? (
          <Link color="primary.2" as={ScrollableLink} to={to}>
            {title}
          </Link>
        ) : (
          <Text as="span" color="contrastSecondary">
            {title}
          </Text>
        )}
        {i < links.length - 1 && (
          <Text as="span" color="contrastSecondary">
            {' '}
            /{' '}
          </Text>
        )}
      </span>
    ))}
  </Text>
)

export default BreadCrumbs
