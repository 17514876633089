import React from 'react'
import PropTypes from 'prop-types'
import Instrument from './Instrument'
import { Box, Grid, Heading } from '../UI'

const RelatedInstruments = ({ related }) => {
  return (
    related &&
    related.edges.length > 2 && (
      <Box as="section" mt={4}>
        <Heading mb={2}>You might also like...</Heading>
        <Grid as="ul" cols={{ _: 1, sm: 2, lg: 4 }} gap={2}>
          {related.edges.map(
            ({ node: instrument }, i) =>
              i < 4 && (
                <li key={i}>
                  <Instrument
                    showPreview={false}
                    vertical={{
                      _: false,
                      xs: false,
                      sm: true,
                      md: true,
                      lg: true,
                      xl: true,
                      xxl: true,
                    }}
                    instrument={instrument}
                  />
                </li>
              )
          )}
        </Grid>
      </Box>
    )
  )
}

RelatedInstruments.propTypes = {}

export default RelatedInstruments
