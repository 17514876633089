import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import get from 'lodash.get'
import { PageTransitionSpring } from '../../animation'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {
  Container,
  Flex,
  Text,
  Box,
  Heading,
  IconButton,
} from '../../components/UI'
import Instrument from '../../components/Instrument/Instrument'
import ScrollableLink from '../../components/ScrollableLink'
import BreadCrumbs from '../../components/BreadCrumbs'
import RelatedInstruments from '../../components/Instrument/RelatedInstruments'
import ContentfulEdit from '../../components/ContentfulEdit'

const InstrumentPost = ({
  pageContext,
  data: {
    contentfulInstrument: instrument,
    relatedInstruments,
    moreInstruments,
  },
}) => {
  const SeoImg =
    get(instrument, 'heroImage.fluid.src') ||
    get(instrument, 'heroImage.fixed.src')
  // merge all recent instruments if there are less than 4
  // instruments in the same category
  let related = []
  if (relatedInstruments && relatedInstruments.edges.length < 4) {
    related = { edges: [...relatedInstruments.edges, ...moreInstruments.edges] }
  } else {
    related = relatedInstruments
  }

  const breadCrumbs = [
    { to: '/instruments', title: 'Instruments' },
    {
      to: `/instruments/type/${pageContext.instrumentTypeSlug}`,
      title: pageContext.instrumentType,
    },
    { title: instrument.title },
  ]
  return (
    <div>
      <SEO
        title={`Rizzolo ${instrument.type.title} - ${instrument.title}`}
        description={instrument.seoDescription}
        image={SeoImg}
        pathname={`instrument/${instrument.slug}`}
        article
      />

      <Layout>
        <PageTransitionSpring animation="fromBottom">
          <Container py={{ _: 2, md: 4 }} px={{ _: 2, md: 3 }}>
            <Flex
              flexDirection={'column'}
              itemScope
              itemType="http://schema.org/IndividualProduct"
            >
              <Box as="article" px={{ _: 0, sm: 0 }}>
                <Box as="header">
                  <Box>
                    <BreadCrumbs links={breadCrumbs} />
                  </Box>
                  <meta itemProp="image" content={SeoImg} />
                  <meta
                    itemProp="category"
                    content={pageContext.instrumentType}
                  />
                  <ContentfulEdit id={instrument.contentful_id} />

                  <Heading
                    rel="bookmark"
                    itemProp="name"
                    as="h1"
                    fontFamily="sans"
                    pt={1}
                    mb={1}
                    color="primary.0"
                    fontSize={{ xs: 4, md: 6, lg: 7 }}
                    fontWeight={900}
                  >
                    {instrument.title}
                  </Heading>
                  <Heading
                    itemprop="description"
                    fontFamily="sans"
                    pb={2}
                    color="primary.0"
                    fontSize={{ xs: 2, sm: 3, md: 5 }}
                    fontWeight={200}
                  >
                    {instrument.subTitle}
                  </Heading>
                </Box>
                <section>
                  <Instrument fullPage={true} instrument={instrument} />
                </section>
                {related && related.edges && related.edges.length > 0 && (
                  <RelatedInstruments related={related} />
                )}
                {/*                 <Flex alignItems="flex-end" mt={3}>
                  <BreadCrumbs links={breadCrumbs} />
                </Flex> */}

                <IconButton
                  mt={{ _: 3, md: 4 }}
                  mb={{ _: 2, md: 3 }}
                  style={{ display: 'inline-flex' }}
                  to={`/instruments/type/${pageContext.instrumentTypeSlug}`}
                  as={ScrollableLink}
                  fontSize={{ _: 10, sm: 12 }}
                  variant={'primary'}
                  small
                  name="chevron-left"
                  transform={'grow-4 left-3'}
                >
                  Back to &nbsp;
                  <strong>{pageContext.instrumentType}</strong>
                </IconButton>
              </Box>
            </Flex>
          </Container>
        </PageTransitionSpring>
      </Layout>
    </div>
  )
}
export default InstrumentPost

export const instrumentPostQuery = graphql`
  query InstrumentPostQuery($id: String!, $instrumentTypeSlug: String!) {
    contentfulInstrument(id: { eq: $id }) {
      ...InstrumentInfo
    }
    relatedInstruments: allContentfulInstrument(
      limit: 4

      filter: { id: { ne: $id }, type: { slug: { eq: $instrumentTypeSlug } } }
    ) {
      edges {
        node {
          ...InstrumentPreview
        }
      }
    }
    moreInstruments: allContentfulInstrument(
      limit: 4
      sort: { fields: [publishDate], order: DESC }

      filter: { id: { ne: $id }, type: { slug: { ne: $instrumentTypeSlug } } }
    ) {
      edges {
        node {
          ...InstrumentPreview
        }
      }
    }
  }
`
